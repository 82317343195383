<!--充值记录-->
<template>
  <div class="AccountHistory">
    <p style="text-align: left;font-size: 30px">{{$t('Account.replenishment.history')}}</p>
    <el-table
        :key="tableKey"
        v-loading="listLoading"
        :data="list"
        border
        fit
        highlight-current-row
        style="width: 100%;"
    >
      <!--      流水号-->
      <el-table-column :label="$t('po.field.serial')" prop="inputNo" align="center" />
      <!--      所属分公司-->
      <el-table-column :label="$t('sys.common.companyCode')" prop="companyCode" align="center" />
      <!--      用户编号-->
      <el-table-column :label="$t('fiPayAdvice.userCode')" prop="userCode" align="center" />
      <!--      姓名-->
      <el-table-column :label="$t('person.name')" prop="createrName" align="center" />
      <!--      充值方式-->
      <el-table-column :label="$t('fiBankbookTempRecharge.moneyType')" prop="tranType" align="center">
        <template v-slot="{row}">
          {{ $lt(initDict.transtypecn, row.tranType ) }}
        </template>
      </el-table-column>
      <!--      支付金额-->
      <el-table-column :label="$t('busi.pay.amount')" prop="localMoney" align="center" />
      <!--      币种-->
<!--      <el-table-column :label="$t('fiShopAcTemp.currencyCode')" prop="" align="center">-->
<!--        <template slot-scope="{row}">-->
<!--          {{ row.localCurrency }} - {{ row.currencyName }}-->
<!--        </template>-->
<!--      </el-table-column>-->
      <!--      汇率-->
      <el-table-column :label="$t('bdNetWorkCostReport.rateCH')" prop="rate" align="center" />
      <!--      &lt;!&ndash;     金额（美元） &ndash;&gt;-->
      <!--      <el-table-column :label="$t('amount.dollar')" prop="money" align="center" />-->
      <!--     付款方式-->
      <el-table-column :label="$t('fiPayAdvice.dealType')" prop="acType" align="center">
        <template slot-scope="{row}">
          <span v-if = "row.acType === 'F$'">$</span>
          <span v-else-if = "row.acType === 'B$'">{{ getSymbol() }}</span>
        </template>
      </el-table-column>
      <!--      状态-->
      <el-table-column :label="$t('Common.status')" prop="status" align="center">
        <template v-slot="{row}">
          <div v-if="row.status === '2'" style="color: blue">{{ $lt('fi_ac_input_status_cn', row.status) }}</div>
          <div v-else-if="row.status === '3'" style="color: red">{{ $lt('fi_ac_input_status_cn', row.status) }}</div>
          <div v-else-if="row.status === '4'" style="color: green">{{ $lt('fi_ac_input_status_cn', row.status) }}</div>
          <div v-else-if="row.status === '9'" style="color: red">{{ $lt('fi_ac_input_status_cn', row.status) }}</div>
          <div v-else>{{ $lt(initDict.status, row.status) }}</div>
        </template>
      </el-table-column>
      <!--      创建时间-->
      <el-table-column :label="$t('Common.createTime')" prop="createTime" align="center">
        <template v-slot="{row}">
          {{ row.createrCode }} / {{ row.createTime }}
        </template>
      </el-table-column>
      <!--      入账时间-->
      <el-table-column :label="$t('fi.credit.time')" prop="recheckeTime" align="center">
        <template v-slot="{row}">
          {{ row.recheckerCode }} / {{ row.recheckeTime }}
        </template>
      </el-table-column>
      <!--      财务确认状态-->
      <el-table-column :label="$t('fiacinput.ficheckstatus')" prop="fiCheckStatus" align="center">
        <template v-slot="{row}">
          <div v-if="row.fiCheckStatus == 1" style="color: blue">{{ $lt(initDict.fiCheckStatus,row.fiCheckStatus ) }}</div>
          <div v-else-if="row.fiCheckStatus == 2" style="color: green">{{ $lt(initDict.fiCheckStatus,row.fiCheckStatus ) }}</div>
          <div v-else>{{ $lt(initDict.fiCheckStatus,row.fiCheckStatus ) }}</div>
        </template>
      </el-table-column>
      <!--       在线支付备注-->
      <el-table-column width="200" :label="$t('menu.onlinePayment.miMember.remark')" prop="onlinePayRemark" align="center" />
    </el-table>
    <!--    分页-->
        <pagination
            v-show="total>0"
            :total="total"
            :page.sync="listQuery.page"
            :limit.sync="listQuery.limit"
            @pagination="getList"
        />
    <span style="float: right">
      <el-button size="mini" type="warning" @click="continueBtn" plain>{{$t('btn.continue')}}</el-button>
    </span>
  </div>
</template>

<script>
import {getFiAcInputs} from "@/api/rus/api";
import {mapGetters} from "vuex";
import Pagination from '@/components/Pagination'
export default {
  name: "AccountHistory",
  components: { Pagination },
  data() {
    return {
      listQuery: {
        page: 1,
        limit: 20
      },
      tableKey:0,
      list: [],
      doLoading: false,
      total: 0,
      btnloading:false,
      listLoading:false,
      initDict:{
        transtypecn:'trans_type_cn',
        fiCheckStatus:'fi_check_status',
        payonlinetype:'fi.payonline.type',
        status:'fi_ac_input_status_cn'
      }
    }
  },
  computed: {
    ...mapGetters([
      'language',
      'userCode',
      'username',
      'companyCode'
    ]),
  },
  created() {
    this.getList()
  },
  methods: {
    getList(){
      this.listLoading = true
      getFiAcInputs({'userCode':this.userCode}).then(res=>{
        this.listLoading = false
        this.list = res.data.records
        console.log(this.list,111)
      }).catch(err=>{
        console.error(err)
        this.listLoading = false
      })
    },
    continueBtn(){
      this.getList()
    },
    getSymbol() {if(this.companyCode === 'MEX'){
        return 'MXN'
      }
    }
  }
}
</script>

<style scoped>

</style>
